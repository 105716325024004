import { useEffect, useRef, useState } from "react";
import { Modal } from "./Modal";
import { Textarea } from "./components/Textarea";
import hero from ".././css/img/hero.png";
import { ContactInformation } from "./ContactInformation";
import { Commenting } from "./Commenting";
import { Meetings } from "./Meetings";
import { DOMAIN } from "../App";
import axios from "axios";
import { InitialState, MeType } from "../redux/interfaces";
import { setMe, setMeets, setSelectedMeet } from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

type Student = MeType;
type StudentState = Student & { selected: boolean };

export const RoomPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [students, setStudents] = useState<StudentState[]>([]);
  const [search, setSearch] = useState("");
  const me = useSelector((state: InitialState) => state.me);

  const onSearchChange = (val: string) => {
    setSearch(val);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      axios
        .get(`${DOMAIN}/meets/student_list/?search=${search}`)
        .then((resp) => {
          setStudents(
            resp.data.map((s: Student) => {
              return { ...s, selected: false };
            })
          );
        });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const sendRef = useRef<HTMLButtonElement>(null);

  const [formData, setFormData] = useState({
    date: "",
    theme: "",
  });

  const toggleStudent = (id: string) => {
    const newStudents = [...students];
    const index = newStudents.findIndex((stud) => stud.id === id);
    const prevSelectedIndex = newStudents.findIndex((s) => s.selected);
    newStudents[prevSelectedIndex] = {
      ...newStudents[prevSelectedIndex],
      selected: false,
    };
    newStudents[index] = {
      ...newStudents[index],
      selected: !newStudents[index].selected,
    };
    setStudents(newStudents);
  };
  const meets = useSelector((state: InitialState) => state.meets);

  const onSend = () => {
    const data = {
      ...formData,
      students: students.filter((s) => s.selected).flatMap((s) => s.id),
    };
    axios
      .post(`${DOMAIN}/meets/create_meet/`, data)
      .then((r) => {
        dispatch(setMeets([r.data, ...meets]));
        setShowModal(false);
        dispatch(setSelectedMeet(r.data));
      })
      .catch((er) => {
        if (er.response) {
          const errors = er.response.data.error;

          if (errors && typeof errors === "object") {
            // Преобразуем ошибки в читаемый формат
            const errorMessages = (Object.entries(errors) as any[])
              .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
              .join("\n");

            alert(`Ошибки валидации:\n${errorMessages}`);
          } else {
            // Если ошибка в другом формате, просто отображаем ее
            const errorMessage = errors || "Произошла ошибка";
            alert(errorMessage);
          }
        } else {
          alert("Произошла ошибка при отправке запроса");
        }
      });
  };

  const dispatch = useDispatch();

  const onLogout = () => {
    axios.post(`${DOMAIN}/users/delete_token/`).then((resp) => {
      if (resp.status === 200) {
        dispatch(setMe(null));
        window.location.href = "/";
      }
    });
  };

  const onIncludeNotifications = () => {
    axios.get(`${DOMAIN}/users/notifications/get_url/`).then((resp) => {
      window.location.href = resp.data.redirect_url;
    });
  };

  return (
    <>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="title">Назначение встречи</div>
          <div className="fields">
            <div className="textarea-parent">
              <span>Тема встречи:</span>
              <div className="textarea-box">
                <Textarea
                  placeholder="Введите тему встречи"
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        theme: e.target.value,
                      };
                    })
                  }
                />
              </div>
            </div>
            <p>
              <span>Дата встречи:</span>
              <input
                type="datetime-local"
                onChange={(e) =>
                  setFormData((prev) => {
                    return {
                      ...prev,
                      date: e.target.value,
                    };
                  })
                }
              />
            </p>
            <div className="students">
              <div className="search-parent">
                <input
                  onChange={(e) => onSearchChange(e.target.value)}
                  placeholder="Начните вводить, чтобы выбрать студента"
                  type="text"
                />
                <svg
                  width="19"
                  height="21"
                  viewBox="0 0 19 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="8.70271"
                    cy="8.65718"
                    r="6.86408"
                    transform="rotate(-40.5872 8.70271 8.65718)"
                    stroke="#6F7FC0"
                    stroke-width="3"
                  />
                  <path
                    d="M16.2702 19.7961C16.8091 20.4252 17.7561 20.4983 18.3852 19.9593C19.0143 19.4203 19.0874 18.4734 18.5484 17.8443L16.2702 19.7961ZM12.461 15.3498L16.2702 19.7961L18.5484 17.8443L14.7392 13.398L12.461 15.3498Z"
                    fill="#6F7FC0"
                  />
                </svg>
              </div>
              {window.innerWidth > 800 && <div></div>}
              {students.length < 1 && (
                <p className="nothing-was-found">Никого не найдено</p>
              )}
              {students.map((student) => (
                <div className="student">
                  <img src={student.avatar} alt="" />
                  <div className="select-name">
                    <div className="name">
                      {student.first_name} {student.last_name}
                    </div>
                    <div
                      onClick={() => toggleStudent(student.id)}
                      className="select"
                    >
                      {student.selected ? "Выбран" : "Выбрать"}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="button-box">
              <button onClick={onSend}>Назначить встречу</button>
            </div>
          </div>
        </Modal>
      )}
      <div className="shadow"></div>
      <div className="bg-img">
        <img src={hero} alt="" />
      </div>
      {!showModal && (
        <header>
          <h1 className="logo">Академия</h1>
          <div className="account-parent">
            <button className="account" onClick={onIncludeNotifications}>
              <h1>Подключить уведомления</h1>
            </button>
            <div className="account">
              <h1>
                {me?.first_name} {me?.last_name}
              </h1>
              <div className="logout" onClick={onLogout}>
                <svg
                  viewBox="0 0 17 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 6.5L5.5 1V5.5H1V8.5H5.5V12L11 6.5Z"
                    fill="white"
                  />
                  <path
                    d="M12 1H16V12H12M5.5 1L11 6.5L5.5 12V8.5H1V5.5H5.5V1Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </header>
      )}
      <div className="parent">
        <ContactInformation />
        <Commenting />
      </div>
      <Meetings setShowModal={setShowModal} />
    </>
  );
};
