import { useEffect, useRef, useState } from "react";

export type TextareaProps = {
  className?: string;
  onSend?(e: any): void;
  placeholder?: string;
  onChange?(e: any): void;
  reference?: React.RefObject<HTMLTextAreaElement>;
};

export const Textarea = ({
  onSend,
  placeholder,
  onChange,
  reference,
}: TextareaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [height, setHeight] = useState(21);

  const adjustTextareaHeight = () => {
    const textarea = ref.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflowY =
        textarea.scrollHeight > 240 ? "scroll" : "hidden";
    }
  };

  const ref = reference || textAreaRef;

  useEffect(() => {
    adjustTextareaHeight();
  }, [height]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeight(e.target.scrollHeight);
    adjustTextareaHeight();
    onChange?.(e);
  };
  const handleTab = (e: any) => {
    e.preventDefault();
    e.target.value += "    ";
  };

  return (
    <textarea
      ref={ref}
      onKeyDown={(e) => {
        if (e.key === "Tab") {
          handleTab(e);
        }
      }}
      onKeyPress={(e) => {
        if (window.innerWidth > 1100) {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            
            onSend?.(e);
            setHeight(21);
          }
        }
      }}
      onChange={handleChange}
      rows={1}
      placeholder={placeholder}
    ></textarea>
  );
};