import axios from "axios";
import { useEffect } from "react";

import { useParams } from "react-router-dom";
import { DOMAIN } from "../../App";

export function SocLogin({ name }: { name: string }) {
  let link = window.location.href;
  useEffect(() => {
    let data: any = {
      tgAuthResult: link.split("=").pop(),
    };
    const ref = window.localStorage.getItem("ref");
    if (ref) {
      data = {
        ...data,
        ref: ref,
      };
    }
    const registFields = window.localStorage.getItem("registFields");

    if (registFields) {
      data = {
        ...data,
        ...JSON.parse(registFields),
      };
    }
    if (name === "yandex") {
      let code = link
        .substring(link.indexOf("code="), link.indexOf("&"))
        .replace("code=", "");
      data = {
        ...data,
        code: code,
      };
    }
    if (name === "vk") {
      let code = link.split("=").pop();
      data = {
        ...data,
        code: code,
      };
    }
    axios.post(DOMAIN + `/users/${name}_callback/`, data).then(() => {
      window.location.href = "/?regist_accept=1";
    });
  }, []);
  return null;
}
