import "./contactInformation.css";
import avatar1 from "../css/img/avatar1.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { InitialState } from "../redux/interfaces";
import axios from "axios";
import { DOMAIN } from "../App";
export function ContactInformation() {
  const me = useSelector((state: InitialState) => state.me);
  const informationFields = [
    { name: "Имя", value: me?.first_name, formName: "" },
    { name: "Фамилия", value: me?.last_name, formName: "" },
    // {
    //   name: "Контактый e-mail",
    //   value: me?.email || "E-mail не введен",
    //   formName: "email",
    // },
    // {
    //   name: "Телефон",
    //   value: me?.phone || "Телефон не введен",
    //   formName: "phone",
    // },
    // {
    //   name: "Ник в telegram",
    //   value: me?.tg || "Ник не введен",
    //   formName: "tg",
    // },
    { name: "Уровень", value: me?.level, formName: "" },
    { name: "Кол-во проведенных встреч", value: me?.meets_count, formName: "" },
  ];

  const editableFields = ["Телефон", "Контактый e-mail", "Ник в telegram"];

  const [formData, setFormData] = useState({
    tg: me?.tg || "",
    email: me?.email || "",
    phone: me?.phone || "",
  });

  useEffect(() => {
    if (me) {
      setFormData({
        tg: me?.tg || "",
        email: me?.email || "",
        phone: me?.phone || "",
      });
    }
  }, [me]);

  const [editMode, setEditMode] = useState(false);
  const onInputChange = (e: any, name: string) => {
    setFormData({ ...formData, [name]: e.target.value });
  };

  const onSave = () => {
    axios.post(`${DOMAIN}/users/me/`, formData).then((resp) => {
      if (resp.status == 200) setEditMode(false);
    });
  };

  return (
    <div className="contact-info-parent">
      <div className="avatar">
        <img id="logo" src={me?.avatar} alt="Моё изображение" />
      </div>
      <div className="information">
        <div className="heading">
          <h1>Контактная информация</h1>
          <p
            onClick={() => {
              if (editMode) onSave();
              setEditMode(!editMode);
            }}
          >
            {editMode ? "сохранить" : "изменить"}
          </p>
        </div>
        {informationFields.slice(0, 2).map((infoField) => (
          <div className="fieid">
            <div className="name">
              <h6>{infoField.name}</h6>
            </div>
            <div className="value">
              <p>{infoField.value}</p>
            </div>
          </div>
        ))}
        <div className="fieid">
          <div className="name">
            <h6>Контактый e-mail</h6>
          </div>
          <div className="value">
            {editMode ? (
              <input
                type="email"
                value={formData.email}
                onChange={(e) => onInputChange(e, "email")}
              />
            ) : (
              <p className={`${formData.email ? "" : "no-value"}`}>
                {formData.email || "E-mail не введен"}
              </p>
            )}
          </div>
        </div>
        <div className="fieid">
          <div className="name">
            <h6>Телефон</h6>
          </div>
          <div className="value">
            {editMode ? (
              <input
                type="tel"
                value={formData.phone}
                onChange={(e) => onInputChange(e, "phone")}
              />
            ) : (
              <p className={`${formData.phone ? "" : "no-value"}`}>
                {formData.phone || "Телефон не введен"}
              </p>
            )}
          </div>
        </div>
        <div className="fieid">
          <div className="name">
            <h6>Ник в telegram</h6>
          </div>
          <div className="value">
            {editMode ? (
              <input
                type="text"
                value={formData.tg}
                onChange={(e) => onInputChange(e, "tg")}
              />
            ) : (
              <p className={`${formData.tg ? "" : "no-value"}`}>
                {formData.tg || "Ник не введен"}
              </p>
            )}
          </div>
        </div>
        {informationFields.slice(2).map((infoField) => (
          <div className="fieid">
            <div className="name">
              <h6>{infoField.name}</h6>
            </div>
            <div className="value">
              <p>{infoField.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
