import { ReactNode, useEffect } from "react";
import "./modal.css";

export type ModalProps = {
  className?: string;
  children: ReactNode;
  onClose(): void;
};

export const Modal = ({ children, onClose, className }: ModalProps) => {
  const onCloseClick = (e: any) => {
    if (!e.target.closest(".content")) {
      onClose?.();
    }
  };
  //   useEffect(() => {
  //     const shadow = document.querySelector(".shadow")
  //     shadow.style.display = "none";
  //   }, [])
  return (
    <div className={`modal ${className || ""}`}>
      <div className="content">
        <div onClick={() => onClose?.()} className="close">
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 10.5475L12.8889 15.4637C13.6 16.1788 14.6667 16.1788 15.3778 15.4637C15.7333 15.1955 16 14.7486 16 14.3017C16 13.8547 15.8222 13.4078 15.4667 13.0503L10.4889 8.04469L15.3778 3.12849C16.0889 2.41341 16.0889 1.2514 15.3778 0.625698C15.1111 0.268156 14.6667 -5.82819e-08 14.1333 -8.15946e-08C13.6 -1.04907e-07 13.2444 0.178771 12.8889 0.536313L8 5.45251L3.11111 0.536312C2.75556 0.268156 2.31111 -5.9836e-07 1.77778 -6.21673e-07C1.33333 -6.411e-07 0.888889 0.17877 0.533334 0.536312C0.177778 0.893854 8.95067e-07 1.34078 8.75531e-07 1.78771C8.55995e-07 2.23464 0.177778 2.68156 0.533334 3.03911L5.42222 7.95531L0.533333 12.8715C-0.177779 13.5866 -0.177779 14.7486 0.533333 15.3743C1.24444 16.0894 2.4 16.0894 3.02222 15.3743L8 10.5475Z"
              fill="white"
              fill-opacity="0.5"
            />
          </svg>
        </div>
        {children}
      </div>
    </div>
  );
};