import { useEffect, useState } from "react";
import "./meetings.css";
import { Modal } from "./Modal";
import { InitialState, Meet } from "../redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { DOMAIN } from "../App";
import axios from "axios";
import { setMeets, setSelectedMeet } from "../redux/actions";
import { convertDateTime } from "../utils/time";
import ClipboardJS from "clipboard";

export function Meetings({ setShowModal }: { setShowModal: any }) {
  const me = useSelector((state: InitialState) => state.me);
  const meets = useSelector((state: InitialState) => state.meets);
  const dispatch = useDispatch();
  const [statusMeet, setStatusMeet] = useState<Meet | null>(null);

  useEffect(() => {
    axios.get(`${DOMAIN}/meets/`).then((resp) => {
      dispatch(setMeets(resp.data.meets));
    });
  }, [dispatch]);

  const tableFields = [
    {
      name: "Дата и время",
      value: "12 августа 2024г 19:00",
    },
    {
      name: "Тема встречи",
      value: "Обсуждение нового научного исследования",
    },
    {
      name: "Студент",
      value: "Фамилия Имя студента",
    },
    {
      name: "Статус встречи",
      value: "Выбрать статус",
    },
    {
      name: "1",
      value: (
        <>
          Ч<br />
          а<br />т
        </>
      ),
    },
  ];

  const toChat = (meet: Meet) => {
    dispatch(setSelectedMeet(meet));
    const scrollTarget = document.querySelector(".commenting-parent h1")!;
    scrollTarget.scrollIntoView({ behavior: "smooth" });
  };

  function getTableFieldContent(tableField: any, meet: any) {
    switch (tableField.name) {
      case "Студент":
        return `${meet.user.first_name} ${meet.user.last_name}`;

      case "Дата и время":
        return convertDateTime(meet.date);

      case "Тема встречи":
        return meet.theme;

      case "Статус встречи":
        return meet.status;

      case "1":
        return (
          <div
            onClick={() => {
              toChat(meet);
            }}
          >
            Ч<br />
            а<br />т
          </div>
        );

      default:
        return "";
    }
  }
  const onMeetStatusChange = (meet: Meet, status: string) => {
    axios
      .post(`${DOMAIN}/meets/update/`, {
        meet_id: meet.id,
        status: status,
      })
      .then((resp) => {
        const newMeets = [...meets];
        const index = newMeets.findIndex((m) => m.id === meet.id);
        newMeets[index].status = status;
        setMeets(newMeets);
        setStatusMeet(null);
      })
      .catch((error) => console.log(error));
  };

  const meetStatuses = [
    "Состоялась",
    "Сорвана по вине эксперта",
    "Сорвана по вине студента",
  ];

  new ClipboardJS(".copy");

  const onCopy = (e: any) => {
    const btn = e.target as HTMLButtonElement;
    btn.style.width = window.getComputedStyle(btn).width;
    btn.textContent = "Ссылка создана";

    setTimeout(() => {
      btn.textContent = "Создать приглошение";
    }, 500);
  };

  return (
    <div className="parent meetings-parent">
      <div className="heading">
        <div className="name">
          <h1>Ваши встречи</h1>
          <div className="arrow">
            <svg
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L7 7.5L13 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="btns">
          <button
            onClick={onCopy}
            className="copy interactive"
            data-clipboard-text={me?.ref}
          >
            Создать приглашение
          </button>
          {me?.is_teacher && (
            <button className="interactive" onClick={() => setShowModal(true)}>
              Назначить встречу
            </button>
          )}
        </div>
      </div>
      <table
        style={{
          paddingBottom: meets.length == 1 ? "40px" : "16px",
        }}
        className="table-columns"
      >
        {meets.map((meet, index) => (
          <tr>
            {tableFields.map((tableField) => (
              <td className="table-column">
                {index === 0 && (
                  <h4
                    style={{
                      visibility:
                        tableField.name === "1" ? "hidden" : "visible",
                    }}
                  >
                    {tableField.name}
                  </h4>
                )}
                <div
                  onClick={() => {
                    if (tableField.name === "Статус встречи") {
                      setStatusMeet(meet);
                    }
                  }}
                  className={`field ${
                    tableField.name === "Статус встречи"
                      ? "interactive status-field"
                      : ""
                  }`}
                >
                  {tableField.name === "Статус встречи" &&
                  statusMeet?.id === meet.id ? (
                    <>
                      {meetStatuses.map((status) => (
                        <p
                          className="status-choice"
                          onClick={() => onMeetStatusChange(meet, status)}
                        >
                          {status}
                        </p>
                      ))}
                    </>
                  ) : (
                    <p>{getTableFieldContent(tableField, meet)}</p>
                  )}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </table>
      <div className="table-columns mobile">
        {meets.map((meet, index) => (
          <div className="tr">
            {tableFields.slice(0, 4).map((tableField) => (
              <div className="table-column">
                <h4
                  onClick={(e) => {
                    if (tableField.name === "Статус встречи") {
                      setStatusMeet(meet);
                    }
                  }}
                  className={`${
                    tableField.name === "Статус встречи" &&
                    statusMeet?.id === meet.id
                      ? "choices"
                      : ""
                  }`}
                  style={{
                    visibility: tableField.name === "1" ? "hidden" : "visible",
                  }}
                >
                  {tableField.name === "Статус встречи" ? (
                    <>
                      <p>{meet.status}</p>
                      {meetStatuses
                        .filter((status) => status !== meet.status)
                        .map((status) => (
                          <p
                            className="status-choice"
                            onClick={() => onMeetStatusChange(meet, status)}
                          >
                            {status}
                          </p>
                        ))}
                    </>
                  ) : (
                    <p>{tableField.name}:</p>
                  )}
                </h4>

                <div className="field status-field">
                  <p>
                    {tableField.name !== "Статус встречи" &&
                      getTableFieldContent(tableField, meet)}
                  </p>
                </div>
              </div>
            ))}
            <div className="btn-box">
              <button
                onClick={() => {
                  toChat(meet);
                }}
                className="to-chat"
              >
                Перейти в чат
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
