import { ActionType } from "./actions";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
  me: null,
  showRoom: false,
  selectedMeet: null,
  meets: [],
};

const rootReducer = (
  state: InitialState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case "setMe":
      return {
        ...state,
        me: action.data.me,
      };
    case "setShowRoom":
      return {
        ...state,
        showRoom: action.data.showRoom,
      };
      case "setSelectedMeet":
        return {
          ...state,
          selectedMeet: action.data.selectedMeet,
        };
        case "setMeets":
          return {
            ...state,
            meets: action.data.meets,
          };
    default:
      return state;
  }
};

export default rootReducer;
