export function convertDateTime(isoString: string) {
  // Создаем объект Date из строки в формате ISO
  const date = new Date(isoString);

  // Получаем компоненты даты и времени
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Месяцы на русском языке
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const month = months[date.getUTCMonth()];

  // Формируем строку в нужном формате
  const formattedDateTime = `${hours}:${minutes}\n${day} ${month} ${year}`;

  return formattedDateTime;
}
