import "./css/vars.css";
import "./css/style.css";
import "./general/header.css";
import { ContactInformation } from "./general/ContactInformation";
import { Commenting } from "./general/Commenting";
import { Meetings } from "./general/Meetings";
import { Modal } from "./general/Modal";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Textarea } from "./general/components/Textarea";
import { SocLogin } from "./general/components/SocLogin";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setMe } from "./redux/actions";
import { RoomPage } from "./general/RoomPage";
import { RegisterPage } from "./general/RegisterPage";
import { InitialState } from "./redux/interfaces";

// export const DOMAIN = "http://127.0.0.1:8080";
export const DOMAIN = "";

function App() {
 const dispatch = useDispatch();
const me = useSelector((state: InitialState) => state.me);

 useEffect(() => {
   axios
     .get(DOMAIN + "/users/me/")
     .then((resp) => {
       dispatch(setMe(resp.data.me));
       window.localStorage.removeItem("ref");
     })
     .catch((error) => {
       if (error.response && error.response.status === 401) {
       } else {
         console.error("An error occurred:", error);
       }
     });
 }, [dispatch]);
  
  return (
    <>
      <Router>
        <Routes>
          {["telegram", "yandex", "vk"].map((soc) => (
            <Route
              key={`${soc}_callback`}
              path={`/users/${soc}_callback/`}
              element={<SocLogin name={soc} />}
            />
          ))}
          {/* <Route path="/" element={<RegistPage />} /> */}
          <Route
            path="/"
            element={
             !me ? <RegisterPage/> : <RoomPage />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
