import "./commenting.css";
import avatar1 from "../css/img/avatar1.png";
import { Textarea } from "./components/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { InitialState } from "../redux/interfaces";
import { useEffect, useRef, useState } from "react";
import { DOMAIN } from "../App";
import axios from "axios";
import { setMeets, setSelectedMeet } from "../redux/actions";
import { convertDateTime } from "../utils/time";
export function Commenting() {
  const selectedMeet = useSelector((state: InitialState) => state.selectedMeet);
  const [commentForm, setCommentForm] = useState({
    text: "",
  });
  const TextareaRef = useRef<HTMLTextAreaElement>(null);
  const me = useSelector((state: InitialState) => state.me);
  const meets = useSelector((state: InitialState) => state.meets);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedMeet && meets.length > 0) {
      dispatch(setSelectedMeet(meets[0]));
    }
  }, [meets]);

  const onSendComment = () => {
    console.log(`${DOMAIN}/meets/send_comment/${selectedMeet!.id}`);
    if (commentForm.text) {
      axios
        .post(`${DOMAIN}/meets/send_comment/${selectedMeet!.id}`, {
          text: commentForm.text,
          meet_id: selectedMeet!.id,
        })
        .then((resp) => {
          if (resp.status === 201 && me) {
            const newMeets = [...meets];
            const index = newMeets.findIndex((m) => m.id === selectedMeet!.id);
            newMeets[index].comments = [
              ...newMeets[index].comments,
              {
                text: commentForm.text,
                author: me,
              },
            ];
            dispatch(setMeets(newMeets));
            TextareaRef!.current!.value = "";
            setCommentForm({
              text: ""
            });
          }
        });
    }
  };

  const onInputComment = (e: any) => {
    setCommentForm({ text: e.target.value });
  };

  return (
    <div className="commenting-parent">
      <h1>Комментирование встреч</h1>
      <div className="theme-meeting">
        <h4>
          Встреча {selectedMeet?.date && convertDateTime(selectedMeet?.date)}:
        </h4>
        <div className="theme">
          <p>
            Студент: {selectedMeet?.user?.first_name}{" "}
            {selectedMeet?.user?.last_name}
          </p>
        </div>
      </div>
      <div className="messages">
        {selectedMeet &&
          selectedMeet.comments.map((comment, index) => (
            <div
              className={`${
                comment.author.id === me?.id
                  ? "experts-message"
                  : "student-message"
              }`}
            >
              <div className="avatar">
                <img src={comment.author.avatar} alt="" />
              </div>
              <div className="surname-message">
                <div className="surname">
                  <p>{comment.author.last_name}</p>
                </div>
                <div className="message">
                  <p>{comment.text}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="textarea-box-parent">
        <div className="textarea-box">
          <Textarea
            onSend={onSendComment}
            reference={TextareaRef}
            onChange={onInputComment}
            placeholder="Написать комментарий"
          />
          <svg
            onClick={onSendComment}
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.2111 10.1056L1.68328 0.34164C0.823725 -0.0881373 -0.0881366 0.823728 0.341641 1.68328L3.74122 8.48245C3.86845 8.73691 4.09805 8.92514 4.37253 9L8.16841 10.0352C9.15092 10.3032 9.15092 11.6968 8.16841 11.9648L4.37253 13C4.09805 13.0749 3.86845 13.2631 3.74122 13.5176L0.341642 20.3167C-0.0881357 21.1763 0.823726 22.0881 1.68328 21.6584L21.2111 11.8944C21.9482 11.5259 21.9482 10.4741 21.2111 10.1056Z"
              fill="white"
              fill-opacity="0.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
