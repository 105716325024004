import axios from "axios";
import { DOMAIN } from "../App";
import "./registerPage.css";
import { useEffect, useState } from "react";

export function RegisterPage() {
  const [formData, setFormData] = useState({
    phone: "",
    comment: "",
  });

  useEffect(() => {
    const registFields = window.localStorage.getItem("registFields");
    if (registFields) {
      setFormData({
        ...JSON.parse(registFields),
      });
    }
  }, []);

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const ref = window.location.search.split("?ref=")[1];
    if (ref) window.localStorage.setItem("ref", ref);
  }, []);

  useEffect(() => {
    setShowError(false);
  }, [formData]);

  const onClickLogo = (name: string) => {
    if (!formData.comment || !formData.phone) {
      setShowError(true);
    } else {
      const localData = JSON.stringify(formData);
      window.localStorage.setItem("registFields", localData);
      axios.post(DOMAIN + `/users/${name}_login/`).then((resp) => {
        window.location.href = resp.data.redirect_url;
      });
    }
  };

  const logos = [
    {
      name: "vk",
      logo: (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M32 0C14.3267 0 0 14.3267 0 32C0 49.6733 14.3267 64 32 64C49.6733 64 64 49.6733 64 32C64 14.3267 49.6733 0 32 0ZM43.8153 35.6395C44.1403 35.9503 44.4664 36.2601 44.7808 36.5816C45.5986 37.4179 47.2404 39.1501 47.7972 40.1281C47.8223 40.1722 47.8473 40.2146 47.87 40.26C48.1533 40.7367 48.22 41.1067 48.08 41.3833C47.8467 41.8433 47.0467 42.07 46.7733 42.09H41.7733C41.4267 42.09 40.7 42 39.82 41.3933C39.1433 40.92 38.4767 40.1433 37.8267 39.3867C36.8567 38.26 36.0167 37.2867 35.17 37.2867C35.0625 37.2865 34.9556 37.3034 34.8533 37.3367C34.2133 37.5433 33.3933 38.4567 33.3933 40.89C33.3933 41.65 32.7933 42.0867 32.37 42.0867H30.08C29.3 42.0867 25.2367 41.8133 21.6367 38.0167C17.23 33.3667 13.2633 24.04 13.23 23.9533C12.98 23.35 13.4967 23.0267 14.06 23.0267H19.11C19.7833 23.0267 20.0033 23.4367 20.1567 23.8C20.3367 24.2233 20.9967 25.9067 22.08 27.8C23.8367 30.8867 24.9133 32.14 25.7767 32.14C25.9385 32.1381 26.0975 32.0969 26.24 32.02C27.3667 31.3933 27.1567 27.3767 27.1067 26.5433C27.1067 26.3867 27.1033 24.7467 26.5267 23.96C26.3333 23.6933 26.0765 23.504 25.8142 23.3698C25.4507 23.1839 25.2816 22.7129 25.6467 22.53C26.42 22.1433 27.8133 22.0867 29.1967 22.0867H29.9667C31.4667 22.1067 31.8533 22.2033 32.3967 22.34C33.4967 22.6033 33.52 23.3133 33.4233 25.7433C33.3933 26.4333 33.3633 27.2133 33.3633 28.1333C33.3633 28.3333 33.3533 28.5467 33.3533 28.7733C33.32 30.01 33.28 31.4133 34.1533 31.99C34.2672 32.0614 34.3989 32.0995 34.5333 32.1C34.8367 32.1 35.75 32.1 38.2233 27.8567C38.8896 26.6636 39.4797 25.4298 39.99 24.1626C40.1376 23.7961 40.2373 23.3556 40.5767 23.1533C40.7046 23.0881 40.8464 23.0549 40.99 23.0567H46.9267C47.5733 23.0567 48.0167 23.1533 48.1 23.4033C48.2467 23.8 48.0733 25.01 45.3633 28.68L44.1533 30.2767C41.8619 33.28 41.7078 33.6242 43.8153 35.6395Z"
            fill="#FFCE06"
          />
        </svg>
      ),
    },
    {
      name: "telegram",
      logo: (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M32 0C14.328 0 0 14.328 0 32C0 49.672 14.328 64 32 64C49.672 64 64 49.672 64 32C64 14.328 49.672 0 32 0ZM47.7173 21.9227L42.464 46.6693C42.0773 48.424 41.032 48.8507 39.5733 48.024L31.5733 42.1307L27.7173 45.848C27.344 46.328 26.7653 46.6347 26.1173 46.6347C26.112 46.6347 26.1093 46.6347 26.104 46.6347L26.672 38.4907L41.4987 25.0987C42.1387 24.5307 41.3547 24.208 40.504 24.776L22.1867 36.312L14.2933 33.848C12.5867 33.3067 12.5387 32.1413 14.6533 31.304L45.496 19.416C46.9307 18.8933 48.1787 19.7573 47.7147 21.9253L47.7173 21.9227Z"
            fill="#FFCE06"
          />
        </svg>
      ),
    },
    {
      name: "yandex",
      logo: (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64ZM42.1722 48.7417V13.9868H30.7285C26.0662 14.4106 19.9205 17.3775 19.9205 25.0066C19.9205 31.1099 24.5828 35.4614 26.9139 36.8742L19.0728 48.7417H25.4305L34.3311 35.3907L29.8808 32.4238C22.2517 26.0662 26.4901 18.649 32.2119 18.649H36.0265V48.7417H42.1722Z"
            fill="#FFCE06"
          />
        </svg>
      ),
    },
  ];
  return (
    <div className="registration-parent">
      <div className="registration">
        <h1>{window.location.search.split("?regist_accept=")[1] ? "Заявка отправлена, ожидайте её одобрения" :"Отправить заявку"}</h1>
        {!window.location.search.split("?regist_accept=")[1] && (
          <>
            <input
              className={`${showError && !formData.phone ? "error" : ""} `}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone: e.target.value,
                })
              }
              type="tel"
              placeholder="Номер телефона"
            />
            <textarea
              className={`${showError && !formData.comment ? "error" : ""} `}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  comment: e.target.value,
                })
              }
              placeholder="Расскажите о себе:
• Кем Вы являетесь (студент/эксперт)? 
• Если вы эксперт, к какому уровню вы себя относите (level 1/level 2/level 3)?"
            />
            <div className="social">
              {logos.map((logo) => (
                <div className="icon" onClick={() => onClickLogo(logo.name)}>
                  {logo.logo}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
