import { MeType, Meet } from "./interfaces";

export type ActionType = {
  type: string;
  data: any;
}

export const setMe = (me: MeType | null) => ({
  type: "setMe",
  data: { me: me },
});
export const setShowRoom = (showRoom: boolean) => ({
  type: "setShowRoom",
  data: { showRoom: showRoom },
});
export const setSelectedMeet = (selectedMeet: Meet) => ({
  type: "setSelectedMeet",
  data: { selectedMeet: selectedMeet },
});
export const setMeets = (meets: Meet[]) => ({
  type: "setMeets",
  data: { meets: meets },
});


